<template>
  <div v-if="jobStages && jobStages.length > 0"
    class="tile is-ancestor is-parent pl-0">
    <div class="tile is-parent">
      <article class="tile is-child box">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">
              Summary
              <button v-show="false"
                class="button is-small is-primary is-inverted tooltip is-tooltip-info"
                :disabled="!canChangeReadOnly"
                :data-tooltip="innerValue.readOnly ? 'Quote is locked' : 'Quote is unlocked'"
                @click="toogleReadOnly()">
                <i class="icon mdi mdi-24px"
                  :class="[innerValue.readOnly ? 'mdi-lock-outline' : 'mdi-lock-open-outline']" />
              </button>
            </p>
          </div>
          <div class="column is-narrow">
            <div class="tags">
              <span v-if="invoice"
                class="tag is-success is-pulled-right">Inv {{ `${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}/${invoice.invoiceType}` }} dated
                {{ $filters.formatDateLocale(invoice.invoiceDate, $userInfo.locale) }}</span>
              <span v-if="excessInvoice"
                class="tag is-success is-pulled-right">Excess Inv {{ `${excessInvoice.prefix}${excessInvoice.invoiceNo}${excessInvoice.suffix}/${excessInvoice.invoiceType}` }} dated
                {{ $filters.formatDateLocale(excessInvoice.invoiceDate, $userInfo.locale) }}</span>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column is-half mr-0">
              <label class="label">Quote No.</label>
              <div class="control">
                <input class="input is-static"
                  :value="`${innerValue.prefix}${innerValue.quoteNo}${innerValue.suffix}`"
                  type="text"
                  placeholder="Quote No."
                  readonly>
              </div>
            </div>
            <div class="field column is-half mr-0">
              <label class="label">Quote Date</label>
              <input v-if="readOnlyView"
                class="input is-static"
                :value="$filters.formatDateLocale(quoteDate, $userInfo.locale)"
                readonly>
              <v-date-picker v-else
                v-model="selectedQuoteDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="quoteDateInputEvent"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Quote Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column is-half mr-0">
              <label class="label">Estimator</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static is-capitalized"
                  :value="innerValue.quoter"
                  readonly>
              </div>
              <div v-else
                class="control">
                <bulma-autocomplete placeholder="Quoter"
                  v-model="innerValue.quoter"
                  :data="filteredQuoters"
                  :keep-first="true"
                  :loading="false"
                  :class-prop="$v.entity.quoter.$error ? 'is-capitalized is-invalid' : 'is-capitalized'" />
              </div>
            </div>
            <div class="field column is-half mr-0">
              <label class="label">Job Stage</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="formatJobStage(jobNSubJobStages.find((j) => j.compositeKey === innerJobNSubJobStageCompositeKey).compositeName)"
                  readonly>
              </div>
              <div v-else
                class="control">
                <div class="select is-fullwidth">
                  <select v-model="innerJobNSubJobStageCompositeKey"
                    @change="handleJobNSubJobStageChanged">
                    <option v-for="jobNSubJobStage in jobNSubJobStagesOptions"
                      :value="jobNSubJobStage.compositeKey"
                      :key="jobNSubJobStage.compositeKey"
                      :disabled="jobNSubJobStage.disabled">
                      <!-- {{ formatJobStage(jobNSubJobStage.compositeName) }} -->
                      <!-- {{ jobNSubJobStage.subJobStageId ? `\u00A0\u00A0\u00A0 ${formatJobStage(jobNSubJobStage.subJobStageName)}` : formatJobStage(jobNSubJobStage.compositeName) }} -->
                      {{ displayJobStage(jobNSubJobStage) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="field">
              <label class="label">Job Stage</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="jobStages.find((j) => j.stage === innerValue.jobStage).description"
                  readonly>
              </div>
              <div v-else
                class="control">
                <div class="select is-fullwidth">
                  <select v-model="innerValue.jobStage"
                    @change="handleJobStageChanged">
                    <option v-for="jobStage in jobStages"
                      :value="jobStage.stage"
                      :key="jobStage.stage">
                      {{ formatJobStage(jobStage.enumDescription) }}
                    </option>
                  </select>
                </div>
              </div>
            </div> -->
            <!-- <div v-if="isSubJobStagesAvailable"
              class="field">
              <label class="label">Sub Job Stage</label>
              <div v-if="readOnlyView"
                class="control">
                <input class="input is-static"
                  :value="subJobStagesBySelectedJobStage.find((j) => j.subJobStageId === innerValue.subJobStage).subJobStageName"
                  readonly>
              </div>
              <div v-else
                class="control">
                <div class="select is-fullwidth">
                  <select v-model="innerValue.subJobStage">
                    <option v-for="subJobStage in subJobStagesBySelectedJobStage"
                      :value="subJobStage.subJobStageId"
                      :key="subJobStage.subJobStageId">
                      {{ formatSubJobStage(subJobStage.subJobStageName) }}
                    </option>
                  </select>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div v-if="isInspectionBookingDateExist"
          class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column is-half mr-0">
              <div class="field">
                <label class="label">Inspection Booking Start Date</label>
                <input v-if="readOnlyView"
                  class="input is-static"
                  :value="$filters.formatDateLocale(innerValue.inspectionBookingStart, $userInfo.locale)"
                  readonly>
                <v-date-picker v-else
                  v-model="inspectionStartDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  :model-config="inspectionStartDate ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field is-fullwidth">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Inspection Start Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          disabled
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="field column is-half mr-0">
              <div class="field">
                <label class="label">Inspection Booking End Date</label>
                <input v-if="readOnlyView"
                  class="input is-static"
                  :value="$filters.formatDateLocale(innerValue.inspectionBookingEnd, $userInfo.locale)"
                  readonly>
                <v-date-picker v-else
                  v-model="inspectionEndDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :model-config="inspectionEndDate ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Inspection End Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          disabled
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
        </div>

        <div v-if="innerValue.repx"
          class="field is-horizontal">
          <div class="field-body columns mt-3">
            <div class="field column mr-0">
              <!-- <bulma-checkbox-date label="Is Repair Booked?"
                v-model="innerValue.isRepairBooked" /> -->
              <label class="label">Is Repair Booked?</label>
              <div>
                <div class="pretty p-default p-round p-smooth ">
                  <input id="chk-pretty-customer-type"
                    v-model="innerValue.isRepairBooked"
                    name="chk-pretty-customer-type"
                    :value="true"
                    type="radio">
                  <div class="state p-primary">
                    <label>Yes</label>
                  </div>
                </div>
                <div class="pretty p-default p-round p-smooth">
                  <input id="chk-pretty-customer-type"
                    v-model="innerValue.isRepairBooked"
                    name="chk-pretty-customer-type"
                    :value="false"
                    type="radio">
                  <div class="state p-primary">
                    <label>No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="innerValue.repx && innerValue.isRepairBooked != null && innerValue.isRepairBooked"
          class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column is-half mr-0">
              <label class="label">Arrived For Repair Date</label>
              <input v-if="readOnlyView"
                class="input is-static"
                :value="$filters.formatDateLocale(innerValue.arrivedForRepairDate, $userInfo.locale)"
                readonly>
              <v-date-picker v-else
                v-model="arrivedForRepairDate"
                ref="datepicker"
                mode="dateTime"
                :minute-increment="5"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                class="is-fullwidth"
                @input="jobEndDateInputEvent"
                :model-config="arrivedForRepairDate ? {} : modelConfig"
                :min-date="jobStartDate"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field has-addons">
                    <div class="control is-expanded has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Arrive for Repair Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        :content="ormJobEndJobEndValidationText"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                    <div class="control">
                      <a class="button tooltip"
                        data-tooltip="Reset dates"
                        @click="clearArrivedForRepairDate()">
                        <span class="icon">
                          <i class="mdi mdi-close mdi-24px" />
                        </span>
                      </a>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="field column is-half mr-0" />
          </div>
        </div>

        <div v-if="(innerValue.repx ? (innerValue.isRepairBooked != null && innerValue.isRepairBooked) : true)"
          class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column is-half mr-0">

              <label class="label">Estimated Start Date</label>
              <input v-if="readOnlyView || isRepairStartDateSynced"
                class="input is-static"
                :value="$filters.formatDateTimeLocale(innerValue.jobStart, $userInfo.locale)"
                readonly>
              <v-date-picker v-else
                v-model="jobStartDate"
                ref="datepicker"
                mode="dateTime"
                :minute-increment="5"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="jobStartDateInputEvent"
                :model-config="jobStartDate ? {} : modelConfig"
                :max-date="jobEndDate"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field is-fullwidth">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        :class="{ 'is-invalid': $v.entity.jobStart.$error }"
                        placeholder="Job Start Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        :content="ormJobEndJobEndValidationText"
                        v-tippy="{ theme: 'danger', enabled: $v.entity.jobStart.$error }"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <span class="help is-info"
                v-if="isRepx">Once the Start Date is synced, it cannot be updated.</span>
            </div>
            <div class="field column is-half mr-0">
              <label class="label">Estimated End Date</label>
              <input v-if="readOnlyView"
                class="input is-static"
                :value="$filters.formatDateLocale(innerValue.jobEnd, $userInfo.locale)"
                readonly>
              <v-date-picker v-else
                v-model="jobEndDate"
                ref="datepicker"
                mode="dateTime"
                :minute-increment="5"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                class="is-fullwidth"
                @input="jobEndDateInputEvent"
                :model-config="jobEndDate ? {} : modelConfig"
                :min-date="jobStartDate"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field has-addons">
                    <div class="control is-expanded has-icons-left">
                      <input type="text"
                        class="input"
                        :class="{ 'is-invalid': $v.entity.jobEnd.$error }"
                        placeholder="Job End Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        :content="ormJobEndJobEndValidationText"
                        v-tippy="{ theme: 'danger', enabled: $v.entity.jobEnd.$error }"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                    <div class="control">
                      <a class="button tooltip"
                        data-tooltip="Reset dates"
                        @click="resetJobStartEnd()">
                        <span class="icon">
                          <i class="mdi mdi-close mdi-24px" />
                        </span>
                      </a>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>

        <div v-if="innerValue.repx && innerValue.isRepairBooked != null && !innerValue.isRepairBooked"
          class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column  mr-0"
              v-if="isRepx && !isRepairBooked">

              <label class="label">Refuse Book Repair Reason</label>
              <div class="control is-expanded">
                <input class="input is-fullwidth"
                  :class="{ 'is-invalid': $v.entity.refuseBookRepairReason.$error }"
                  v-model="innerValue.refuseBookRepairReason">
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.refuseBookRepairReason.required">Refuse Book Repair Reason is required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body columns">
            <div class="field column mr-0">
              <!--         inScope are the higher level settings in insurer table-->
              <!--         driveable are the higher level settings in company settings-->
              <div v-if="insurer.inScope || $company.setting.driveable">
                <div class="is-divider" />
                <div class="is-flex">
                  <div v-if="$company.setting.driveable">
                    <label class="label">Driveable</label>
                    <div class="control">
                      <div class="pretty p-switch p-fill">
                        <input type="checkbox"
                          @change="driveableOnChange"
                          :checked="!!innerValue.driveable">
                        <div class="state p-primary">
                          <label>{{ innerValue.driveable ? 'Yes' : 'No' }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="$company.setting.driveable && insurer.inScope"
                    class="is-divider-vertical" />
                  <div v-if="insurer.inScope">
                    <label class="label">In Scope</label>
                    <div class="control">
                      <div class="pretty p-switch p-fill">
                        <input type="checkbox"
                          @change="inScopeOnChange"
                          :checked="!!innerValue.inScope">
                        <div class="state p-primary">
                          <label>{{ innerValue.inScope ? 'Yes' : 'No' }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$userInfo.isSupportUser || tempGemCan">
          <div class="is-divider" />
          <label class="label">Enable Assessment History</label>
          <div class="control">
            <div class="pretty p-switch p-fill">
              <input type="checkbox"
                @change="assessmentHistoryChange"
                :disabled="assessmentHistoryToggleDisable"
                :checked="innerValue.isAssessmentEnabled">
              <div class="state p-primary">
                <label>{{ innerValue.isAssessmentEnabled ? 'Yes' : 'No' }}</label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!innerValue.isNew && !readOnlyView && $user.hasDelete($route.meta.id)">
          <div class="is-divider" />
          <div class="buttons has-addons is-centered">
            <span class="button"
              :class="{ 'is-success is-selected': !innerValue.deleted }"
              @click="toggleActive(true)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span class="button"
              :class="{ 'is-danger is-selected': innerValue.deleted }"
              @click="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </div>
      </article>
    </div>
    <late-reason-modal v-if="isRepairLateReasonActive"
      :active.sync="isRepairLateReasonActive"
      @saveContinue="handleRepairLateReasonClose()"
      @close="handleRepairLateReasonClose()"
      :show-icon="true"
      :cancel-disabled="false"
      v-model="innerValue">
      <p slot="text-title">Late Reason</p>
    </late-reason-modal>
    <div class="tile is-parent pl-0">
      <article class="tile is-child box">
        <p class="title">Totals</p>
        <quote-detail-summary v-if="!value.isAssessmentEnabled"
          v-model="value" />
        <quote-detail-assessment-summary v-else
          v-model="value" />
      </article>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import { QuoteService } from '@/services'
import StoreMixin from './storeMixin'
import QuoteDetailSummary from './QuoteDetailSummary'
import QuoteDetailAssessmentSummary from './QuoteDetailAssessmentSummary'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { InvoiceTypes, EventHubTypes } from '@/enums'
import { DateTime } from 'luxon'
import JobStageTypes from '@/enums/JobStageTypes'
// import BulmaCheckboxDate from '@/components/BulmaCheckbox/BulmaCheckboxDate.vue'
import LateReasonModal from './components/LateReasonModal.vue'
import InnerQuoteService from './QuoteService'

export default {
  name: 'QuoteSummary',
  inject: ['$vv'],
  components: {
    QuoteDetailSummary,
    QuoteDetailAssessmentSummary,
    BulmaAutocomplete,
    // BulmaCheckboxDate,
    LateReasonModal
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    insurer: null
  },
  data: () => {
    return {
      innerValue: null,
      selectedQuoteDate: null,
      selectedJobStage: null,
      sortKey: null,
      quoters: [],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        start: {
          timeAdjust: '08:00:00'
        },
        end: {
          timeAdjust: '08:00:00'
        }
      },
      isPauseWatch: false,
      bookedInSortKey: null,
      dragValue: null,
      jobStartDate: null,
      jobEndDate: null,
      inspectionStartDate: null,
      inspectionEndDate: null,
      arrivedForRepairDate: null,
      isRepairLateReasonActive: false,
      oriJobStartDate: null,
      oriJobEndDate: null,
      quoteRepx: null,
      innerJobNSubJobStageCompositeKey: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: 'hover',
          isInteractive: false // Defaults to true when using slot
        }
      }
    },
    filteredQuoters() {
      return this.quoters.filter((option) => {
        return option.toString().toLowerCase().indexOf(this.innerValue.quoter.toLowerCase()) >= 0
      })
    },
    canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    invoice() {
      return this.innerValue.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Quote)
    },
    excessInvoice() {
      return this.innerValue.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Excess)
    },
    ormJobEndJobEndValidationText() {
      if (!this.$v.entity.jobStart.required || !this.$v.entity.jobEnd.required) {
        return 'Required by ORM'
      } else if (!this.$v.entity.jobStart.validJobStart || !this.$v.entity.jobEnd.validJobEnd) {
        return 'Invalid date range'
      }
    },
    assessmentHistoryToggleDisable() {
      if (this.value.quoteAssessments && this.value.quoteAssessments.length > 0) {
        return true
      }
      if (this.value.invoices && this.value.invoices.length > 0) {
        return true
      }
      if (this.value.orm && this.value.orm.messageNo != '0') {
        return true
      }
      if (this.value.subQuoteNos.length > 1) {
        return true
      }
      return false
    },
    isInspectionBookingDateExist() {
      return this.innerValue.inspectionBookingStart != null && this.innerValue.inspectionBookingStart != undefined && this.innerValue.repx!=null
    },
    // isSubJobStagesAvailable() {
    //   return this.subJobStagesBySelectedJobStage != null && this.subJobStagesBySelectedJobStage.length > 0
    // },
    // subJobStagesBySelectedJobStage() {
    //   return this.subJobStages.filter((i) => i.jobStage == this.innerValue.jobStage)
    // },
    isRepairBooked() {
      return this.innerValue.isRepairBooked
    },
    isRepx() {
      return this.innerValue.repx != null
    },
    isRepairStartDateSynced() {
      return this.quoteRepx ? this.quoteRepx.isRepairStartDateSynced : false
    },
    // jobNSubJobStageCompositeKey() {
    //   let jobStage = this.innerValue.jobStage == null ? '' : ''
    //   let subJobStage = this.innerValue.subJobStage == null ? '' : ''
    //   return this.innerValue.jobStage || '' + '|' + subJobStage
    // }
    jobNSubJobStagesOptions() {
      const isDisableParentWithSub = !!this.isRepx
      const addedParents = []
      const filterJobNSubJobStages = this.jobNSubJobStages.map(option => {
        if (option.subJobStageId !== '' && !addedParents.includes(option.jobStageId)) {
          addedParents.push(option.jobStageId)
          return [
            { compositeKey:`${option.jobStageId}|`, compositeName: `${option.jobStageName}`, disabled: isDisableParentWithSub },
            option
          ]
        }
        return [option]
      }).flat()

      // if (!isDisableParentWithSub) {
      //   return filterJobNSubJobStages.filter(i => !(i.subJobStageId))
      // }
      return filterJobNSubJobStages
    },

    //TEMP check for GEMCan company - REMOVE AFTER ASSESSMENT HISTORY PHASE 2
    tempGemCan() {
      return (this.$userInfo.isCustomerAdministrator && this.$company.info.companyId === 'a5ffbdc0-41d2-4e7f-8ecf-e51beac57e6b')
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        if (!this.isPauseWatch) {
          this.$emit('input', newVal)
        }
      },
      deep: true
    },
    selectedQuoteDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.quoteDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.quoteDate = null
      }
    },
    jobStartDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.jobStart = newVal.toISOString().split('.')[0] + 'Z'
        if (this.innerValue.jobEnd && this.sortKey < this.bookedInSortKey && this.insurer.autoBookedInForDueInOut) {
          this.selectedJobStage = JobStageTypes.BookedIn
          this.innerValue.jobStage = JobStageTypes.BookedIn
        }
      } else {
        this.innerValue.jobStart = null
      }
    },
    jobEndDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.jobEnd = newVal.toISOString().split('.')[0] + 'Z'
        //to prompt late reason
        this.isRepairLateReasonActive = this.compare1DateTo2(newVal, this.oriJobEndDate)
        if (this.innerValue.jobStart && this.sortKey < this.bookedInSortKey && this.insurer.autoBookedInForDueInOut) {
          this.selectedJobStage = JobStageTypes.BookedIn
          this.innerValue.jobStage = JobStageTypes.BookedIn
        }
      } else {
        this.innerValue.jobEnd = null
      }
    },
    arrivedForRepairDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.arrivedForRepairDate = newVal.toISOString().split('.')[0] + 'Z'
        if (this.innerValue.arrivedForRepairDate && isNaN(oldVal)) {
          this.innerValue.jobStage = JobStageTypes.VehicleArrived
        }
      } else {
        this.innerValue.arrivedForRepairDate = null
      }
    }
  },
  async created() {
    this.getQuoters()
    this.innerValue = _cloneDeep(this.value)
    this.selectedQuoteDate = new Date(`${this.innerValue.quoteDate}`)
    this.selectedJobStage = this.innerValue.jobStage
    this.sortKey = this.getJobStageSortKey(this.innerValue.jobStage)
    this.bookedInSortKey = this.getJobStageSortKey(JobStageTypes.BookedIn)

    if (this.innerValue.jobStart) {
      this.jobStart = new Date(`${this.innerValue.jobStart}`)
      this.selectedJobStart = new Date(`${this.innerValue.jobStart}`)
      this.jobStartDate = new Date(`${this.innerValue.jobStart}`)
      this.oriJobStartDate = this.jobStartDate
    }
    if (this.innerValue.jobEnd) {
      this.jobEnd = new Date(`${this.innerValue.jobEnd}`)
      this.selectedJobEnd = new Date(`${this.innerValue.jobEnd}`)
      this.jobEndDate = new Date(`${this.innerValue.jobEnd}`)
      this.oriJobEndDate = this.jobEndDate
    }
    if (this.innerValue.inspectionBookingStart) this.inspectionStartDate = new Date(`${this.innerValue.inspectionBookingStart}`)
    if (this.innerValue.inspectionBookingEnd) this.inspectionEndDate = new Date(`${this.innerValue.inspectionBookingEnd}`)
    if (this.innerValue.arrivedForRepairDate) this.arrivedForRepairDate = new Date(`${this.innerValue.arrivedForRepairDate}`)

    this.setInscopeDefault()
    await this.loadQuoteRepxIfExist()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.isPauseWatch = true
      this.innerValue = _cloneDeep(this.value)
      this.isPauseWatch = false
    })

    let jobStage = this.innerValue.jobStage
    if (jobStage) {
      let subJobStage = this.innerValue.subJobStage || ''
      this.innerJobNSubJobStageCompositeKey = jobStage + '|' + subJobStage
    }
  },

  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    compare1DateTo2(dateStr1, dateStr2) {
      if (dateStr1 && dateStr2) {
        const date1 = new Date(dateStr1)
        const date2 = new Date(dateStr2)

        if (date1 > date2) {
          return true
        }
      }

      return false
    },
    quoteDateInputEvent(event) {
      this.selectedQuoteDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    },
    inScopeOnChange(event) {
      this.innerValue.inScope = !!event.target.checked
    },
    driveableOnChange(event) {
      this.innerValue.driveable = !!event.target.checked
    },
    assessmentHistoryChange(event) {
      this.innerValue.isAssessmentEnabled = !!event.target.checked
    },
    clearArrivedForRepairDate() {
      if (this.arrivedForRepairDate !== null) {
        this.arrivedForRepairDate = null
      }
    },
    resetJobStartEnd() {
      if (!this.isRepx || !this.isRepairStartDateSynced) {
        if (this.jobStartDate !== null) {
          this.jobStartDate = null
        }
      }
      if (this.jobEndDate !== null) {
        this.jobEndDate = null
      }

      if (this.selectedJobStage == JobStageTypes.BookedIn && this.insurer.autoBookedInForDueInOut) {
        this.selectedJobStage = JobStageTypes.FollowUpRequired
        this.innerValue.jobStage = JobStageTypes.FollowUpRequired
      }

      // Reset validation objects
      this.$v.entity.jobStart.$reset()
      this.$v.entity.jobEnd.$reset()
    },
    jobStartEndDragEvent(event) {
      this.dragValue = event
    },
    jobStartEndInputEvent(event) {},
    async getQuoters() {
      this.quoters = await QuoteService.getQuoters()
    },
    formatJobStage(value) {
      if (value.toLowerCase() === 'none') {
        return '-- Job Stage --'
      } else {
        return value
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
          .replace(/^./, function (str) {
            return str.toUpperCase()
          })
      }
    },
    formatSubJobStage(value) {
      if (value.toLowerCase() === '' || value == null) {
        return '-- Sub Job Stage --'
      } else {
        return value
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
          .replace(/^./, function (str) {
            return str.toUpperCase()
          })
      }
    },
    displayJobStage(jobNSubJobStages) {
      const { compositeName, subJobStageId, subJobStageName } = jobNSubJobStages
      const hasSubJobStage = (subJobStageId)
      let name = (hasSubJobStage) ? subJobStageName : compositeName
      if (name.toLowerCase() === '' || name == null) {
        return '-- Sub Job Stage --'
      } else {
        name = this.formatJobStage(name)
      }
      return hasSubJobStage ? `\u00A0\u00A0\u00A0 ${name}` : name
    },
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
        this.innerValue.readOnly = !this.innerValue.readOnly
        this.innerValue.readOnlyStatuses.splice(this.innerValue.subQuoteNo, 1, this.innerValue.readOnly)
      }
    },
    toggleActive(active) {
      this.innerValue.deleted = !this.innerValue.deleted
    },
    getNoOfDays(startDate, endDate) {
      const d1 = DateTime.fromISO(startDate.toISOString())
      const d2 = DateTime.fromISO(endDate.toISOString())
      return d2.diff(d1, 'days').toObject().days + 1
    },
    getJobStageSortKey(stageId) {
      const index = this.jobStages.findIndex((i) => i.stage === stageId)
      const sortKey = this.jobStages[index].sortKey
      return sortKey
    },
    setInscopeDefault() {
      if (this.innerValue.isNew && this.insurer.inScope) {
        this.innerValue.inScope = this.insurer.inScope
      }
    },
    jobStartDateInputEvent(event) {},
    jobEndDateInputEvent(event) {},
    handleJobStageChanged() {
      this.innerValue.subJobStage = null
    },
    handleJobNSubJobStageChanged(event) {
      const selected = event.target.value
      if (selected && selected.includes('|')) {
        let result = selected.split('|')
        this.innerValue.jobStage = result[0] || null
        this.innerValue.subJobStage = result[1] || null
      } else {
        this.innerValue.jobStage = null
        this.innerValue.subJobStage = null
      }
    },
    handleRepairLateReasonClose() {
      this.isRepairLateReasonActive = false
    },
    async loadQuoteRepxIfExist() {
      const quoteRepx = await InnerQuoteService.getQuoteRepx(this.innerValue.quoteId)
      if (quoteRepx) {
        this.quoteRepx = quoteRepx
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.readonly-pointer {
  cursor: pointer;
}
</style>
